export const routes = {
  account: {
    url: '/account',
    login: {
      url: '/account/login',
    },
    auth: {
      url: '/account/auth',
    },
  },
  goggleRedirect: {
    url: '/GoogleRedirect',
  },
  gogglega4Redirect: {
    url: '/GoogleGA4Redirect',
  },
  googleAdsRedirect:{
    url: '/GoogleAdsRedirect'
  },
  facebookRedirect: {
    url: '/FacebookRedirect',
  },
  tiktokRedirect: {
    url: '/TikTokRedirect',
  },
  metaOrganicRedirect:{
    url : '/MetaOrganicRedirect',
  },
  snapchatRedirect: {
    url: '/SnapchatRedirect',
  },
  shopifyStore: {
    url: '/ShopifyStore',
  },
  overview: {
    url: '/overview',
  },
  marketingoverview: {
    url: '/marketing-overview',
  },
  marketingoverviewdetail: {
    url: '/marketing-overview-detail',
  },
  paidmarketingoverview: {
    url: '/marketing-paidmarketing',
  },
  socialOrganicOverview:{
    url: '/organic-social',
  },
  shopifydashboard: {
    url: '/shopifydashboard',
  },
  paidMarketingPerformance: {
    url: '/PaidMarketingPerformance',
    channel: {
      url: '/PaidMarketingPerformance/:channelId',
    },
  },
  customerSegment: {
    url: '/customer-segment',
  },
  // productPerformance: {
  //   url: '/product-performance',
  // },
  overallTrendPerformance: {
    url: '/overall-trend-performance',
  },
  revenueSpend: {
    url: '/revenue-spend',
  },
  brain: {
    url: '/brain',
  },
  dataloading: {
    url: '/dataloading',
  },
  marketingAnalyzer: {
    url: '/marketing-analyzer',
    channel: {
      url: '/marketing-analyzer/channel',
    },
    campaign: {
      url: '/marketing-analyzer/campaign',
    },
  },
  shoppingJourney: {
    url: '/shopping-journey',
  },
  pyramid: {
    url: '/pyramid',
  },
  multipyramid: {
    url: '/multipyramid',
  },
  pyramidprodsalesanalysis: {
    url: '/pyramidprodsalesanalysis',
  },
  pyramidspendmanager: {
    url: '/pyramidspendmanager',
  },
  analyticcontent: {
    url: '/analyticcontent',
  },
  pyramidwidget: {
    url: '/pyramidwidget',
  },
  settings: {
    url: '/settings',
  },
  dataExport: {
    url: '/dataExport',
  },
  userManagement: {
    url: '/UserManagement',
  },
  support: {
    url: '/Support',
  },
  profile: {
    url: '/profile',
  },
  customerJourney: {
    url: '/customer-journey',
    marketingOnly: {
      url: '/customer-journey/marketing-only',
    },
  },
  campaigns: {
    url: '/campaigns',
    marketingOnly: {
      url: '/campaigns/marketing-only',
    },
  },
  tiktokDashboard: {
    url: '/tiktok-Dashboard',
    channel: {
      url: '/tiktok-Dashboard/:channelId',
    },
    // clicks: {
    //   url: '/tikTok/tiktok-Dashboard/clicks',
    // },
  },

  productProfitability: {
    url: '/product-profitability',
  },

};
