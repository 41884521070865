import { ListSubheader, List } from '@mui/material';
import { VideoModal } from 'components/videoModal';
import { FC, useEffect, useState } from 'react';

import { SidebarMenuItem } from './SidebarMenuItemLayout';
import { menuItems, marketingOnlyMenuItems, MenuItemsProps } from './SidebarMenuItems';
import { MenuWrapper, SubMenuWrapper } from './styles';
import { useSelector } from 'hooks';

interface Props {
  maximized?: boolean;
  ismarketing?: boolean;
  companyName?: string;
}

export const SidebarMenu: FC<Props> = ({ maximized, ismarketing, companyName }) => {
  const [videoURL, setVideoURL] = useState('https://www.youtube.com/embed/qIWysveujLg?si=_WLEvE2LK8Cw2osW');
  const [showVideoModal, setShowVideoModal] = useState(false);
  const pyramidloaddata = useSelector(state => state.pyramidloaddataSlice.loaddata);
  const [dataLoaded, setDataLoaded] = useState(false); // State variable to track if data is loaded
  const hasPyramidToken = Boolean(localStorage.getItem('pyramidToken'));
  const [filteredMenuItems, setFilteredMenuItems] = useState<MenuItemsProps[]>(menuItems); // State to hold filtered menu items

  useEffect(() => {
    if ((pyramidloaddata && pyramidloaddata.length > 0 && pyramidloaddata[0].accessToken) || hasPyramidToken) {
      setDataLoaded(true); // Set dataLoaded to true
    } else {
      setDataLoaded(false); // Set dataLoaded to true
    }
  }, [pyramidloaddata]); // Only run this effect when pyramidloaddata changes

  const handleVideoModalClose = () => {
    setShowVideoModal(false);
  };

  const handleMenuSetVideo = (val: string) => {
    if(val !== undefined)
    {
      setVideoURL(val);
      setShowVideoModal(true);
    }
  };

  const handleClick = (link: string, newTab?: boolean) => {
    if (link) {
      if (newTab) {
        // Open link in a new tab
        window.open(link, '_blank');
      } else {
        // Open link in the same tab
        window.location.href = link;
      }
    }
  };

  // Function to get filtered menu items based on the current dataLoaded state
  const getFilteredMenuItems = (menuItems: MenuItemsProps[], dataLoaded: boolean): MenuItemsProps[] => {
    if (process.env.REACT_APP_ENV !== 'development' && process.env.REACT_APP_ENV !== 'uat') {
      return menuItems.map(section => ({
        ...section,
        items: section.items
          .map(item => {
            // Check for Self Service Analytics item and set disable based on dataLoaded
            if (item.name === 'Self Service Analytics') {
              return {
                ...item,
                disable: !dataLoaded, // Disable if data is not loaded
              };
            }
            // For all other items, keep the original state
            return item;
          })
          .filter(
            item =>
              item.name !== 'Customer Segmentation' &&
              item.name !== 'Ad Spend Analysis' &&
              item.name !== 'Product Sales Analysis' &&
              item.name !== 'Spend Manager' &&
              item.name !== 'New Analytics',
          ),
      }));
    }
    return menuItems.map(section => ({
      ...section,
      items: section.items.map(item => {
        // Set disable based on dataLoaded and token presence for Self Service Analytics
        if (item.name === 'Self Service Analytics') {
          return {
            ...item,
            disable: !dataLoaded, // Disable if data is not loaded or no token is present
            tooltip: !dataLoaded ? 'This dashboard is not part of your current package, please contact customer.success@vennflow.com for more information on this feature' : undefined,
          };
        }
        // For all other items, keep the original state
        return item;
      }),
    })); // Return original items if conditions do not match
  };

  // Update filteredMenuItems whenever dataLoaded changes
  useEffect(() => {
    const updatedMenuItems = ismarketing
      ? getFilteredMenuItems(marketingOnlyMenuItems, dataLoaded)
      : getFilteredMenuItems(menuItems, dataLoaded);
    setFilteredMenuItems(updatedMenuItems);
  }, [dataLoaded, ismarketing]); // Run this effect when dataLoaded or ismarketing changes

  return (
    <>
      {filteredMenuItems.map(section => (
        <MenuWrapper key={section.name}>
          <List
            component="div"
            subheader={
              maximized ? (
                <ListSubheader component="div" className="MuiListSubheader-root">
                  {section.name === 'General' ? companyName ?? 'General' : section.name}
                </ListSubheader>
              ) : null
            }
          >
            {section.items.map(item => (
              <SubMenuWrapper key={item.name}>
                <List>
                  <SidebarMenuItem
                    item={item}
                    disabled={item.disable}
                    tooltip={item.tooltip}
                    maximized={maximized}
                    handleVideoSet={() => handleMenuSetVideo(item.videoUrl)}
                    onClick={!item.disable ? () => handleClick(item.link, item.newTab) : undefined}
                  />
                </List>
              </SubMenuWrapper>
            ))}
          </List>
        </MenuWrapper>
      ))}

      {showVideoModal ? <VideoModal videoUrl={videoURL} handleClose={handleVideoModalClose} /> : <></>}
    </>
  );
};
