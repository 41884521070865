import { onboardingApi } from 'api/api';
import { Response } from 'types/api/api';
import { FeatureData, FeatureDataGA4, GetSystemAccessIdGoogleAds, GetSystemAccessIdParams, GetSystemAccessIdParamsGA4, GoogleAdsAccount } from 'types/connectors/connectors';
import { SelectedFeatures, SelectedFeaturesGA4, SelectedFeaturesGoogleAds } from 'types/connectors/google-analytics-data';

const getInstallLink = (isGoogleAds:boolean = false) =>
  onboardingApi.get(`google/AuthUrl?redirectUri=${isGoogleAds? process.env.REACT_APP_GOOGLEADS_REDIRECT_URL :
    process.env.REACT_APP_GOOGLE_REDIRECT_URL}&isGoogleAds=${isGoogleAds}`);

 
const getAccounts = (systemAccessId: string) =>
  onboardingApi.get<Response<FeatureData[]>>(`google/analyticsAccounts/${systemAccessId}`);

const getProperties = (systemAccessId: string, accountId: string) =>
  onboardingApi.get(`google/analyticsAccounts/${systemAccessId}/${accountId}/properties`);



  const getInstallLinkGA4 = () =>
  onboardingApi.get(`google/AuthUrl?redirectUri=${process.env.REACT_APP_GOOGLEGA4_REDIRECT_URL}`);


  const getPropertiesGA4 = (systemAccessId: string, accountId: string) =>
  onboardingApi.get(`google/analytics4Accounts/${systemAccessId}/${accountId}/properties`);


  const getViewsGA4 = (systemAccessId: string) =>
  onboardingApi.get<Response<FeatureDataGA4[]>>(
    `google/analytics4Accounts/${systemAccessId}`,
  );
  

const getViews = (systemAccessId: string, accountId: string, propertyId: string) =>
  onboardingApi.get<Response<FeatureData[]>>(
    `google/analyticsAccounts/${systemAccessId}/${accountId}/properties/${propertyId}/profiles`,
  );

const getSystemAccessId = (params: GetSystemAccessIdParams) =>
  onboardingApi.get('google/exchangeAccessCodeForAccessToken', { params });

const getSystemAccessIdGA4 = (params: GetSystemAccessIdParamsGA4) =>
  onboardingApi.get('google/exchangeAccessCodeForAccessToken', { params });

//google ads
const getSystemAccessGoogleAds = (params: GetSystemAccessIdGoogleAds) =>
  onboardingApi.get('google/ExchangeGoogleAdsAccessCodeForAccessToken', { params });

const getGoogleAdsAccounts = (systemAccesId: string) =>
    onboardingApi.get<Response<GoogleAdsAccount[]>>(`google/GoogleAdsAccounts/${systemAccesId}`)

const saveGoogleAdsSelection =(
  clientId: string,
  clientBrandId: string,
  clientBrandStoreId: string,
  selectedFeatures: SelectedFeaturesGoogleAds,
) =>
  onboardingApi.post<Response<FeatureData[]>>('google/analyticsFeatures', {
    clientId,
    systemId: '197BC7DC-217C-4E0C-9793-0D07195E577C',
    clientBrandId,
    clientBrandStoreId,
    features: [
      {
        id: 'gAdsAccount',
        value: selectedFeatures.account?.id,
      },
      {
        id: 'gAdsDisplayName',
        value: selectedFeatures.accountDisplayName?.value ?? '',
      },
      {
        id: 'gAdsCurrencyCode',
        value: selectedFeatures.accountCurrencyCode?.value ?? '',
      },
    ],
  });

const saveSelection = (
  clientId: string,
  clientBrandId: string,
  clientBrandStoreId: string,
  selectedFeatures: SelectedFeatures,
) =>
  onboardingApi.post<Response<FeatureData[]>>('google/analyticsFeatures', {
    clientId,
    systemId: 'D2ABED9D-4423-4092-B385-F3BF8AD6A557',
    clientBrandId,
    clientBrandStoreId,
    features: [
      {
        id: 'gaAccount',
        value: selectedFeatures.account?.id,
      },
      {
        id: 'gaProperty',
        value: selectedFeatures.property?.id,
      },
      {
        id: 'gaProfile',
        value: selectedFeatures.view?.id,
      },
    ],
  });

const saveSelectionGA4 = (
  clientId: string,
  clientBrandId: string,
  clientBrandStoreId: string,
  selectedFeatures: SelectedFeaturesGA4,
) =>
  onboardingApi.post<Response<FeatureData[]>>('google/analyticsFeatures', {
    clientId,
    systemId: '9A698DDE-D736-4165-B49F-EDDFB384BCF9',
    clientBrandId,
    clientBrandStoreId,
    features: [
    {
      id:'ga4PropertyId',
      value:selectedFeatures?.view.propertyId
    },
    {
      id:'ga4PropertyDN',
      value:selectedFeatures?.view.propertyDisplayName
    },
    {
      id:'ga4PropertyCC',
      value:selectedFeatures?.view.currencyCode
    },
    {
      id:'ga4PropertyTZ',
      value:selectedFeatures?.view.timezone
    },
    {
      id:'ga4PropertySL',
      value:selectedFeatures?.view.serviceLevel
    },
    {
      id:'ga4PropertyIC',
      value:selectedFeatures?.view.industryCategory
    },

    {
      id:'ga4AccountId',
      value:selectedFeatures?.account.name
    },
    {
      id:'ga4AccountRC',
      value:selectedFeatures?.account.regionCode
    },
    {
      id:'ga4AccountDN',
      value:selectedFeatures?.account.displayName
    }
    ],
  });

export const googleAnalyticsApi = {
  getInstallLink,
  getInstallLinkGA4,
  getAccounts,
  getProperties,
  getViews,
  getSystemAccessId,
  getSystemAccessIdGA4,
  saveSelectionGA4,
  saveSelection,
  saveGoogleAdsSelection,
  getPropertiesGA4,
  getViewsGA4,
  getSystemAccessGoogleAds,
  getGoogleAdsAccounts
};
