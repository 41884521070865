import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'types/users/users';

import { UserSlice } from './types';

const initialState: UserSlice = {
  user: null,
  isAuthenticated: false,
  isInitialized: false,
  isMarketingOnly: false,
  accessToken: null,
  changePasswordLoading: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initializeSuccess: (state, action: PayloadAction<{ user: User; accessToken: string }>) => {
      const { user, accessToken } = action.payload;

      state.user = user;
      state.accessToken = accessToken;
      state.isAuthenticated = true;
      state.isInitialized = true;
      state.isMarketingOnly = user.isNonRetail;
    },
    initializeFailed: state => {
      state.accessToken = null;
      state.user = null;
      state.isAuthenticated = false;
      state.isInitialized = true;
      state.isMarketingOnly = false;
    },
    logout: state => {
      state.accessToken = null;
      state.user = null;
      state.isAuthenticated = false;
      state.isInitialized = true;
      state.isMarketingOnly = false;
    },
    refreshUser: (state, action: PayloadAction<{user: User}>) =>{
        state.user = action.payload.user;
    },
    processChangePassword: state => {
      state.changePasswordLoading = true;
    },
    changePasswordSuccess: state => {
      state.changePasswordLoading = false;
    },
    changePasswordFailed: state => {
      state.changePasswordLoading = false;
    },
  },
});
